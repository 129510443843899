<template>
  <v-container fluid>
    <page-header title="禮品設定">
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateGift' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="GiftDetail"
      @options-update="getList()"
    >
    </datatable>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'GiftList',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '禮品名稱', value: 'name' },
      { text: '所需積分', value: 'point_required' },
      { text: '庫存', value: 'inventory' },
      { text: '狀態', value: 'status' },
      { text: '', value: 'actionView', align: 'end', width: 150 },
    ],
  }),
  methods: {
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      try {
        let payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
        }

        const user = this.getCurrentUserData()
        const { data, total } = await this.$Fetcher.GetGifts(payload, user.id, user.token)
        this.tableItemTotal = total
        this.tableData = data.map(el => {
          return {
            id: el.id,
            name: el.name,
            point_required: el.point_required,
            inventory: el.inventory,
            status: el.status
          }
        })
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },
  },

  mounted() {
    this.getQueries()
  },
}
</script>
